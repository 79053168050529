import { FlexCard } from '../Card'
import { graphql } from 'gatsby'

const IframeBlock = (props) => {
  const { iframeHeight, iframeUrl, iframeId } = props

  const iframeStyle = {
    border: '0',
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%',
    height: `${iframeHeight}px`,
  }
  return (
    <FlexCard>
      <iframe
        title={iframeId}
        id={iframeId}
        src={iframeUrl}
        frameBorder={'0'}
        width={'100%'}
        height={'100%'}
        style={iframeStyle}
      />
    </FlexCard>
  )
}

export const IframeBlockFragment = graphql`
  fragment IframeBlock on contentful_IframeBlock {
    __typename
    sys {
      id
    }
    iframeHeight
    iframeId
    iframeScript
    iframeUrl
  }
`

export default IframeBlock
