import Cookies from 'js-cookie'
import { FormContextProvider } from '../form/FormContext'
import { Container, Column, Label } from '@dfds-ui/react-components'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'
import {
  FirstLastNameFields,
  FormField,
  PhoneNumberField,
  CheckBoxField,
  useFormSubmit,
  FormSubmitButton,
} from '../form'
import { string } from 'yup'
import { FormForgetMeNatureOfEnquiry } from './FormForgetMeNatureOfEnquiry'
import { graphql, navigate } from 'gatsby'
import { FlexBox } from '../FlexBox'
import styled from '@emotion/styled'
import { track } from '@dfds-frontend/tracking'
import { useLocaleContext } from '../LocaleContext'

const StyledCheckboxField = styled(CheckBoxField)`
  margin-bottom: 10px;
`

export const FormForgetMe = ({
  emailTemplate,
  sys,
  formProps,
  thankYouPageUrlSlug,
}) => {
  const { submitForm, isSubmitted, setReCaptchaRef, setIsReCaptchaVisible } =
    useFormSubmit()

  const { t } = useTranslation()
  const stringValidation = string()
  const { locale, localeLower, language, country } = useLocaleContext()

  const handleSubmit = async (values) => {
    await submitForm({
      languageId: locale,
      templateId: emailTemplate.sys.id,
      formData: {
        ...values,
        forgetMyDetails: !!values.forgetMyDetails,
        showProfileHistory: !!values.showProfileHistory,
        permissionDetails: !!values.permissionDetails,
        amendDetails: !!values.amendDetails,
        contactPreference: !!values.contactPreference,
        paxCustomer: !!values.paxCustomer,
        shippingCustomer: !!values.shippingCustomer,
        logisticsCustomer: !!values.logisticsCustomer,
      },
      formType: 'formForgetMe',
    })

    track({
      event: 'formSubmit',
      formName: 'formforgetme',
      formID: sys.id,
      countryCode: country,
      languageCode: language,
      gaCookie: Cookies.get('_ga') || '',
    })
  }

  if (isSubmitted) {
    navigate(`/${localeLower}/${thankYouPageUrlSlug.slug}`)
  }

  return (
    <div
      css={css`
        background-color: white;
        padding-left: 20px !important;
        padding-right: 20px !important;
      `}
    >
      <FormContextProvider
        onSubmit={handleSubmit}
        requiredFieldError={t('FORM-ERROR-MESSAGE-REQUIRED')}
      >
        <FormForgetMeInternal
          stringValidation={stringValidation}
          t={t}
          formProps={formProps}
          handleSubmit={handleSubmit}
          setReCaptchaRef={setReCaptchaRef}
          setIsReCaptchaVisible={setIsReCaptchaVisible}
        />
      </FormContextProvider>
    </div>
  )
}

const FormForgetMeInternal = ({
  stringValidation,
  t,
  formProps,
  handleSubmit,
  setReCaptchaRef,
  setIsReCaptchaVisible,
}) => {
  return (
    <Container>
      <Column m={12}>
        {/* <Text styledAs={'interfaceBody'}>{t('TITLE-FOR-FORGET-ME-FORM')}</Text> */}
        <h1>{t('TITLE-FOR-FORGET-ME-FORM')}</h1>
      </Column>
      <FirstLastNameFields required />
      <Column m={12}>
        <FormField
          name="email"
          type="email"
          validation={stringValidation}
          required
          label={t('FORM-FIELD-NAME-EMAIL')}
        />
      </Column>
      <Column m={12}>
        <FormField
          name="alternativeEmail"
          type="email"
          label={t('FORM-FIELD-NAME-ALTERNATIVE-EMAIL')}
        />
      </Column>
      <PhoneNumberField
        labelProps={{ text: t('FORM-FIELD-NAME-PHONE-NUMBER') }}
        countryCodeProps={{
          name: 'countryCode',
          validation: stringValidation,
        }}
        numberProps={{
          name: 'phone',
          validation: stringValidation,
        }}
        required
      />
      <Column m={12}>
        <p
          css={css`
            margin-bottom: 0px;
          `}
        >
          <Label>{t('FORM-FIELD-NAME-ADDITIONAL-INFO')}</Label>
        </p>
        <Label>{`(${t(
          'FORM-FIELD-EXPLANATION-MAXIMUM-300-CHARACTERS',
        )})`}</Label>
        <FormField name="additionalInfo" type="text" multiline />
      </Column>
      <FormForgetMeNatureOfEnquiry formProps={formProps} />
      <Column m={12}>
        <Label>{t('FORM-FIELD-NAME-CUSTOMER-TYPE')}</Label>
      </Column>
      <Column m={12}>
        <StyledCheckboxField type="checkbox" name="paxCustomer">
          {t('FORM-FIELD-NAME-PASSENGER-TRAVEL')}
        </StyledCheckboxField>
        <StyledCheckboxField type="checkbox" name="shippingCustomer">
          {t('FORM-FIELD-NAME-FREIGHT-SHIPPING')}
        </StyledCheckboxField>
        <StyledCheckboxField type="checkbox" name="logisticsCustomer">
          {t('FORM-FIELD-NAME-DOOR-TO-DOOR-LOGISTIC-SOLUTIONS')}
        </StyledCheckboxField>
      </Column>
      <Column m={12}>
        <FlexBox>
          <FormSubmitButton
            handleSubmit={handleSubmit}
            reCaptchaProps={{
              setReCaptchaRef,
              setIsReCaptchaVisible,
            }}
          />
        </FlexBox>
      </Column>
    </Container>
  )
}

export const query = graphql`
  fragment FormForgetMe on contentful_FormForgetMe {
    __typename
    sys {
      id
    }
    formTitle
    emailTemplate {
      sys {
        id
      }
    }
    thankYouPageUrlSlug {
      slug
    }
  }
`
