import { graphql } from 'gatsby'
import { Headline } from '@dfds-ui/react-components'

import { Markdown } from '../Markdown'
import { FlexCard } from '../Card'
import { AspectImage, ImageSlider } from '../Image'
import { Video } from '../Video'
import { EntryLink } from './../EntryLink'
import { gridMap } from '../../utils'
import { PaxTimeTable } from '../PaxTimeTable'

const TextAndMediaLayout = (props) => {
  const {
    title,
    text,
    makeTitleH1,
    images,
    imagesCollection,
    links,
    linksCollection,
    lightboxes,
    mediaType,
    videoId,
    videoOverlayImage,
    reverse,
    imageCreditLinksCollection,
  } = props.contentRef || props

  const {
    mediaPosition,
    mediaWidth,
    backgroundColor,
    blockWidth,
    imageBehaviour = 'cover',
  } = props

  let mediaContent = null
  const getMediaType = (type) => {
    const types = {
      'Youtube-video': 'YouTube',
      'Vimeo-video': 'Vimeo',
    }
    return types[type]
  }
  const componentImages = images || (imagesCollection && imagesCollection.items)

  if (
    videoId &&
    (mediaType === 'Youtube-video' || mediaType === 'Vimeo-video')
  ) {
    mediaContent = (
      <Video
        videoId={videoId}
        type={getMediaType(mediaType)}
        overlayImage={videoOverlayImage?.url}
        videoName={title}
      />
    )
  } else {
    mediaContent =
      componentImages &&
      (componentImages.length === 1 ? (
        <AspectImage {...componentImages[0]} imageBehaviour={imageBehaviour} />
      ) : (
        <ImageSlider
          imageCollection={componentImages}
          imageBehaviour={imageBehaviour}
        />
      ))
  }

  const isDark = !(!backgroundColor || backgroundColor === 'White')
  const linkReferences = linksCollection ? linksCollection.items : links || null
  const imageWidth =
    mediaPosition !== 'top'
      ? typeof mediaWidth === 'object'
        ? mediaWidth
        : gridMap[mediaWidth]
      : { md: 12 }
  const isReverse =
    reverse || mediaPosition === 'bottom' || mediaPosition === 'right'

  return (
    <FlexCard
      width={gridMap[blockWidth]}
      mediaWidth={imageWidth}
      noPadding={false}
      reverse={isReverse}
      media={mediaContent}
      dark={isDark}
      action={
        linkReferences &&
        linkReferences.length > 0 && (
          <>
            {linkReferences.map((link) => (
              <EntryLink
                key={link?.sys?.id}
                backgroundcolor={isDark}
                {...link}
              />
            ))}
          </>
        )
      }
    >
      {title && <Headline as={makeTitleH1 ? 'h1' : 'h2'}>{title}</Headline>}
      {text && <Markdown source={text} />}
      {imageCreditLinksCollection &&
        imageCreditLinksCollection.items.map(
          ({ sys, url, linkText }) =>
            url &&
            linkText && (
              <div key={sys.id}>
                <a href={url} target="_blank">
                  {linkText}
                </a>
              </div>
            ),
        )}
      {lightboxes && (
        <>
          {lightboxes.map((link) => (
            <PaxTimeTable isModal key={link.contentRef.sys.id} {...link} />
          ))}
        </>
      )}
    </FlexCard>
  )
}

export const query = graphql`
  fragment TextAndMediaLayout on contentful_TextAndMediaLayout {
    __typename
    sys {
      id
    }
    mediaPosition
    mediaWidth
    backgroundColor
    blockWidth
    imageBehaviour
    contentRef {
      ...TextAndMediaContent
    }
  }
`

export default TextAndMediaLayout
