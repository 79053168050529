import { CheckBoxField, FormField, RadioGroup } from '../form'
import { Column, Label, Radio } from '@dfds-ui/react-components'
import { FlexBox } from '../FlexBox'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNil } from 'ramda'
import styled from '@emotion/styled'

const StyledFlexBox = styled(FlexBox)`
  display: ${(props) =>
    !isEmpty(props.checkValue) && !isNil(props.checkValue) ? 'flex' : 'none'};
`

const StyledContactPreferenceContainer = styled.div`
  display: ${(props) =>
    !isEmpty(props.checkValue) && !isNil(props.checkValue) ? 'block' : 'none'};
`

const StyledCheckboxField = styled(CheckBoxField)`
  margin-bottom: 10px;
`

export const FormForgetMeNatureOfEnquiry = ({ formProps }) => {
  const { t } = useTranslation()

  const toggleAmendDetails = (event) => {
    const newValue = {
      amendDetails: event.target.checked ? ['on'] : '',
    }
    if (!event.target.checked) {
      newValue.amendFrom = ''
      newValue.amendTo = ''
    }
    formProps.setValues({ ...formProps.values, ...newValue })
  }

  const togglecontactPreference = (event) => {
    const newValue = {
      contactPreference: event.target.checked ? ['on'] : '',
    }

    if (!event.target.checked) {
      newValue.contactBy = ''
    }

    formProps.setValues({ ...formProps.values, ...newValue })
  }

  return (
    <>
      <Column m={12}>
        <Label>{t('FORM-FIELD-NAME-NATURE-OF-ENQUIRY')}</Label>
      </Column>
      <Column m={12}>
        <StyledCheckboxField type="checkbox" name="forgetMyDetails">
          {t('FORM-FIELD-NAME-FORGET-MY-DETAILS')}
        </StyledCheckboxField>
        <StyledCheckboxField type="checkbox" name="showProfileHistory">
          {t('FORM-FIELD-NAME-SHOW-PROFILE-HISTORY')}
        </StyledCheckboxField>
        <StyledCheckboxField type="checkbox" name="permissionDetails">
          {t('FORM-FIELD-NAME-HWO-DO-YOU-HAVE-MY-PERMISSION')}
        </StyledCheckboxField>
        <StyledCheckboxField
          type="checkbox"
          name="amendDetails"
          onChange={toggleAmendDetails}
          checked={formProps.values.amendDetails}
        >
          {t('FORM-FIELD-NAME-AMEND-MY-DETAILS')}
        </StyledCheckboxField>
        <StyledFlexBox checkValue={formProps.values.amendDetails}>
          <Column m={6}>
            <Label>{t('FORM-FIELD-NAME-AMEND-MY-DETAILS-FROM')}</Label>
            <FormField name="amendFrom" type="text" />
          </Column>
          <Column m={6}>
            <Label>{t('FORM-FIELD-NAME-AMEND-MY-DETAILS-TO')}</Label>
            <FormField name="amendTo" type="text" />
          </Column>
        </StyledFlexBox>
        <StyledCheckboxField
          type="checkbox"
          name="contactPreference"
          onChange={togglecontactPreference}
          checked={formProps.values.contactPreference}
        >
          {t('FORM-FIELD-NAME-CONTACT-PREFERENCES')}
        </StyledCheckboxField>
        <StyledContactPreferenceContainer
          checkValue={formProps.values.contactPreference}
        >
          <FormField name="contactBy">
            <RadioGroup displayBlock>
              <Radio value="phone">
                {t('FORM-FIELD-NAME-CONTACT-BY-PHONE')}
              </Radio>
              <Radio value="email">
                {t('FORM-FIELD-NAME-CONTACT-BY-EMAIL')}
              </Radio>
            </RadioGroup>
          </FormField>
        </StyledContactPreferenceContainer>
      </Column>
    </>
  )
}
