import { FlexCard } from './../Card'
import { Markdown } from '../Markdown'
import AspectImage from '../Image/AspectImage'
import { graphql } from 'gatsby'
import { EntryLink } from '../EntryLink'
import { Headline } from '@dfds-ui/react-components'
import { gridMap } from '../../utils'

const BioPromo = (props) => {
  const { resumeLink, image, name, jobTitle, shortDescription, blockWidth } =
    props
  const cardWidth =
    typeof blockWidth === 'object' ? blockWidth : gridMap[blockWidth]

  return (
    <FlexCard
      action={<EntryLink {...resumeLink} />}
      width={cardWidth}
      media={<AspectImage {...image} aspectRatio="3/2" />}
    >
      <Headline as={'h3'} styledAs={'h3'}>
        {name}
      </Headline>
      <Headline as={'span'} styledAs={'h4'}>
        {jobTitle}
      </Headline>
      <div style={{ marginTop: 20 }}>
        <Markdown source={shortDescription} />
      </div>
    </FlexCard>
  )
}

export const query = graphql`
  fragment BioPromo on contentful_BioPromo {
    __typename
    sys {
      id
    }
    name
    jobTitle
    shortDescription
    blockWidth
    image {
      ...Asset
    }
    resumeLink: resume {
      __typename
      ...ItemLink
    }
  }
`

export default BioPromo
