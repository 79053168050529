import { gql, useQuery } from '@apollo/client'
import { DynamicForm } from '@dfds-frontend/forms-ui'
import { createFormsClient } from '../../apollo/client'
import { usePageTrackingData } from '../../tracking'
import { FlexCard } from './../Card'
import { InfoBlock } from './../InfoBlock'

const ConsentContentType = ({ component, env }) => {
  const { id } = component.entries[0]
  const { data, loading, error } = useQuery(
    gql`
      query ($id: String!) {
        formConsentCheckboxField(id: $id) {
          consentText {
            entryTitle
            consentType
            textBody
          }
        }
      }
    `,
    { client: createFormsClient(env), variables: { id } },
  )
  if (loading) return null
  if (error)
    return (
      <InfoBlock
        contentRef={{
          title: `Error: ${error.message}`,
          text: 'please report at support@dfds.com',
        }}
      />
    )
  return (
    <InfoBlock
      contentRef={{
        title: data?.formCheckboxField.consentText.entryTitle,
        text: data?.formCheckboxField.consentText.textBody,
      }}
    />
  )
}

export const XSpaceRefComponent = ({ component }) => {
  const { getPageTrackingData } = usePageTrackingData()
  const {
    bookingEngine,
    businessArea,
    currentLocale: locale,
    contentfulId,
  } = getPageTrackingData()
  const env =
    (process.env.GATSBY_CONTENTFUL_ENV === 'master' && 'prod') ||
    process.env.GATSBY_CONTENTFUL_ENV
  /* eslint-disable no-unsafe-optional-chaining */
  const { contentTypeName } = component?.entries[0]
  if (contentTypeName === 'Form') {
    return (
      <FlexCard>
        <DynamicForm
          id={component.entries[0].id}
          disableReCaptcha
          locale={locale}
          submitEndpoint="https://api.hellman.oxygen.dfds.cloud/prod/dynamic-forms/forms/submit"
          config={{
            clientArgs: {
              env: process.env.GATSBY_CONTENTFUL_T_ENV,
              token: process.env.GATSBY_CONTENTFUL_T_ACCESS_TOKEN,
            },
          }}
          googleMapsApiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
          businessArea={businessArea}
          bookingEngine={bookingEngine}
          pageID={contentfulId}
        />
      </FlexCard>
    )
  }
  if (contentTypeName === 'Form Checkbox Field') {
    return <ConsentContentType component={component} env={env} />
  }
  return process.env.NODE_ENV === 'development' && contentTypeName
}
