import { css } from '@emotion/react'

import { FlexCard } from './../Card'
import { PromoBlock } from './../PromoBlock'
import { IconBlockList } from './../IconBlockList'
import { InfoBlock } from './../InfoBlock'
import { PageContentTextSection } from './../PageContentTextSection'
import { FreightRoutesListBox } from '../FreightRoutesListBox'
import BioPromo from './../PromoBlock/BioPromo'
import { ContactBox } from '../ContactBox'
import { PageContentAccordionList } from '../PageContentAccordionList'
import { LinkboxCarousel } from '../LinkboxCarousel'
import { MapLayout } from '../Map'
import {
  NewsletterSubscribeBlock,
  NewsletterUnsubscribeBlock,
} from '../Newsletter'
import TextAndMediaLayout from '../TextAndMedia/TextAndMediaLayout'
import IframeBlock from '../IframeBlock/IframeBlock'
import {
  TourOperatorWidget,
  ExternalWidget,
  Trustpilot,
  Tagbox,
} from '../Widgets'
import { MediaLibraryEntry } from '../MediaLibraryEntry'
import { FootballCalendar } from '../FootballCalendar'
import NewsArticleList from '../NewsArticleList/NewsArticleList'
import { ShareChartSet } from '../ShareChartSet'
import { ChartSet } from '../ChartSet'
import { SharePriceBlock } from '../SharePriceBlock'
import { FormContactUs } from '../FormContactUs/FormContactUs'
import { FormForgetMe } from '../FormForgetMe/FormForgetMe'
import { ErrorBoundary } from 'react-error-boundary'
import { PageContentTextWithLanguageSwitcher } from '../PageContentTextWithLanguageSwitcher'
import { PaxTimeTable } from '../PaxTimeTable'
import { XSpaceRefComponent } from '../XSpaceRefComponent'

const LocalisationWrapper = (props) => {
  return <ContentEntry {...props.localisedContentReference} />
}

const components = Object.entries({
  PromoBlock,
  IconBlockList,
  InfoBlock,
  PageContentTextSection,
  PageContentTextWithLanguageSwitcher,
  TextAndMediaLayout,
  FreightRoutesListBox,
  BioPromo,
  ContactBox,
  PageContentAccordionList,
  LinkboxCarousel,
  MapLayout,
  NewsletterSubscribeBlock,
  NewsletterUnsubscribeBlock,
  IframeBlock,
  TourOperatorWidget,
  ExternalWidget,
  MediaLibraryEntry,
  FootballCalendar,
  NewsArticleList,
  ShareChartSet,
  SharePriceBlock,
  ChartSet,
  FormContactUs,
  FormForgetMe,
  LocalisationWrapper,
  PaxTimeTable,
  XSpaceRefComponent,
  Trustpilot,
  Tagbox,
})

const ContentEntry = (props) => {
  if (!props.__typename) return null
  const Component = components.find(([key]) => {
    if (props.__typename.startsWith('contentful_')) {
      return key === props.__typename.split('_')[1]
    }
    return key === props.__typename
  })
  if (Component) {
    const C = Component[1]
    return (
      <ErrorBoundary
        FallbackComponent={() => (
          <FlexCard
            css={css`
              border: 1px solid red;
            `}
          >
            <pre>{JSON.stringify(props, null, 2)}</pre>
          </FlexCard>
        )}
      >
        <C {...props} />
      </ErrorBoundary>
    )
  }
  return JSON.stringify(props)
}

export default ContentEntry
