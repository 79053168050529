import { Link } from 'gatsby'
import { css } from '@emotion/react'
import { media, theme } from '@dfds-ui/theme'
import { Headline, ButtonStack, Button } from '@dfds-ui/react-components'
import { ChevronRight } from '@dfds-ui/icons/system'
import { track } from '@dfds-frontend/tracking'

import { formatDate } from '../../utils'
import { AspectImage } from '../Image'
import { FlexCard } from '../Card'
import { useLocalizedLinkTrackingData } from '../EntryLink/LocalizedLink'

const ArticleLink = (props) => {
  const { locale, slug, children, ...rest } = props
  if (!locale || !slug) {
    return children
  }
  return (
    <Link {...rest} to={`/${locale}/${slug}`}>
      {children}
    </Link>
  )
}

const List = (props) => {
  const {
    locale,
    enlargeFirstArticle,
    newsArticleCollection,
    onLoadMore,
    articleItemSize,
    link,
    blockWidth,
    showLoadMore,
    loadButtonTitle,
  } = props

  const { getLocalizedLinkTrackingData } = useLocalizedLinkTrackingData()

  return (
    <>
      {newsArticleCollection.map((item) => {
        return (
          <ArticleLink
            key={item.sys.id}
            locale={locale}
            slug={
              item.linkedFrom.urlSlugCollection.items[0] &&
              item.linkedFrom.urlSlugCollection.items[0].slug
            }
          >
            <FlexCard
              width={{ md: 12 }}
              mediaWidth={{ md: 4 }}
              smallSpacing={true}
              media={<AspectImage src={item.image.url} />}
            >
              <p
                css={css`
                  font-size: 15px;
                  line-height: 18px;
                  margin: 0 0 5px;
                  margin-bottom: ${blockWidth === 'one-third' ? '5px' : '20px'};
                `}
              >
                {`${item.location}, ${formatDate(item.publicationDate)}`}
              </p>
              <Headline
                as={'h4'}
                css={css`
                  margin: 0 0 5px;
                  font-size: ${blockWidth === 'one-third' ? '16px' : '20px'};
                  line-height: ${blockWidth === 'one-third' ? '21px' : '26px'};
                  color: ${theme.colors.text.secondary.primary};
                `}
              >
                {item.title}
              </Headline>
              {blockWidth !== 'one-third' && (
                <p
                  css={css`
                    overflow: hidden;

                    ${media.greaterThan('m')`
                      height: 1.5em;
                    `}
                  `}
                >
                  {item.subtitle}
                </p>
              )}
            </FlexCard>
          </ArticleLink>
        )
      })}
      {showLoadMore &&
        !enlargeFirstArticle &&
        articleItemSize !== 'small' &&
        blockWidth === 'full-width' && (
          <ButtonStack align="center">
            <Button variation="secondary" onClick={() => onLoadMore()}>
              {loadButtonTitle}
            </Button>
          </ButtonStack>
        )}
      {link && (
        <Link
          to={`/${locale}/${link.destination.slug}`}
          onClick={() =>
            link.ctaButtonTracking && track(getLocalizedLinkTrackingData(link))
          }
          css={css`
            display: flex;
            width: 100%;
            justify-content: flex-end;
            align-items: center;
            background-color: ${theme.colors.surface.primary};
            padding: 15px 20px;
            text-decoration: none;
            font-weight: 700;

            svg {
              margin: 2px 0 0 10px;
            }
          `}
        >
          {link.linkText}
          <ChevronRight color={theme.colors.text.secondary.primary} />
        </Link>
      )}
    </>
  )
}

export default List
