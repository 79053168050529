import { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { useQuery, gql } from '@apollo/client'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { format } from 'date-fns'
import { SelectField } from '@dfds-ui/forms'
import { media, theme } from '@dfds-ui/theme'
import { ChevronRight } from '@dfds-ui/icons/system'
import { Headline, Skeleton } from '@dfds-ui/react-components'

import { useLocaleContext } from '../LocaleContext'
import List from './List'
import { FlexCard } from '../Card'
import { AspectImage } from '../Image'
import { formatDate, gridMap } from '../../utils'

const GET_NEWSARTICLES = gql`
  query NewsArticle(
    $limit: Int = 5
    $skip: Int = 0
    $from: DateTime!
    $to: DateTime!
  ) {
    newsArticleCollection(
      where: { publicationDate_gt: $from, publicationDate_lt: $to }
      limit: $limit
      skip: $skip
      order: publicationDate_DESC
    ) {
      total
      items {
        sys {
          id
        }
        linkedFrom {
          urlSlugCollection(limit: 1) {
            items {
              slug
            }
          }
        }
        image {
          title
          description
          url
        }
        title
        subtitle
        location
        publicationDate
      }
    }
  }
`

const ReadMoreLink = styled(Link)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  background-color: ${theme.colors.surface.primary};
  padding: 20px 0 0;
  text-decoration: none;
  font-weight: 700;

  svg {
    margin: 2px 0 0 10px;
  }
`

const NewsArticleList = (props) => {
  const {
    showFilter,
    showLoadMore,
    articlesToShowInitially,
    loadButtonTitle,
    enlargeFirstArticle,
    articleItemSize,
    blockWidth,
    link,
  } = props

  const listOfYears = Array(Number(format(new Date(), 'yyyy')) - 2010)
    .fill(0)
    .map((_, index) => Number(format(new Date(), 'yyyy')) - index)
  const listOfYearsOptions = listOfYears.map((year) => ({
    value: year,
    label: year,
  }))

  const { localeLower } = useLocaleContext()

  const [filter, setFilter] = useState(Number(format(Date.now(), 'yyyy')))

  const from = Number(filter - 1).toString()
  const to = Number(filter + 1).toString()

  const { loading, error, data, fetchMore } = useQuery(GET_NEWSARTICLES, {
    variables: {
      skip: 0,
      limit: articlesToShowInitially || 10,
      from: from,
      to: to,
    },
  })

  const onLoadMore = () =>
    fetchMore({
      variables: {
        skip: data.newsArticleCollection.items.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev

        return {
          newsArticleCollection: {
            ...data.newsArticleCollection,
            items: [
              ...prev.newsArticleCollection.items,
              ...fetchMoreResult.newsArticleCollection.items,
            ],
          },
        }
      },
    })

  if (loading || error || !data)
    return (
      <>
        {showFilter && (
          <Skeleton
            variant="rect"
            css={css`
              height: 40px;
              min-width: 100%;
              margin-bottom: 40px;
            `}
          />
        )}
        <div
          data-test="wrapper"
          css={css`
            display: flex;
            flex-direction: column;
            margin-bottom: 40px;
            width: 100%;
            flex-wrap: wrap;

            ${media.greaterThan('l')`
              width: ${
                enlargeFirstArticle || articleItemSize !== 'Small'
                  ? '100%'
                  : 'calc(33% - 20px)'
              } !important;
              ${enlargeFirstArticle && 'height: 520px;'}
            `}
          `}
        >
          {enlargeFirstArticle && (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                width: 100%;
                margin: 0 0 40px 0;

                ${media.greaterThan('l')`
                  flex-direction: row;
                  margin-right: 40px;
                  width: 50%;
                  flex: 0 0 100%;
                `}
              `}
            >
              <Skeleton
                variant="rect"
                css={css`
                  width: 100%;
                  height: 300px;
                  background-color: ${theme.colors.text.dark.disabled};

                  ${media.greaterThan('l')`
                    height: 520px;
                    width: 50%;
                  `}
                `}
              />
              <Skeleton
                variant="rect"
                css={css`
                  width: 100%;
                  height: 140px;

                  ${media.greaterThan('l')`
                    height: 520px;
                    width: 50%;
                  `}
                `}
              />
            </div>
          )}
          {Array(enlargeFirstArticle ? 4 : 5)
            .fill()
            .map((i) => (
              <div
                key={i}
                css={css`
                  display: flex;
                  flex-direction: column;
                  margin-bottom: 5px !important;

                  ${media.greaterThan('l')`
                    flex-direction: row;
                    width: ${enlargeFirstArticle ? 'calc(50% - 40px)' : '100%'};
                  `}
                `}
              >
                <Skeleton
                  variant="rect"
                  css={css`
                    width: 100%;
                    height: 300px;
                    background-color: ${theme.colors.text.dark.disabled};

                    ${media.greaterThan('l')`
                      height: 125px;
                      width: 250px;
                    `}
                  `}
                />
                <Skeleton
                  variant="rect"
                  css={css`
                    width: 100%;
                    height: 140px;

                    ${media.greaterThan('l')`
                      height: 125px;
                    `}
                  `}
                />
              </div>
            ))}
        </div>
      </>
    )

  const { newsArticleCollection } = data

  let articlesToShow = newsArticleCollection.items
  if (enlargeFirstArticle) {
    articlesToShow = newsArticleCollection.items.slice(1.5)
  }

  return (
    <>
      {(!enlargeFirstArticle || articleItemSize === 'Small') && showFilter && (
        <SelectField
          defaultValue={filter}
          label=""
          value={filter}
          name="yearFilter"
          placeholder={'Latest news'}
          css={css`
            width: 100%;
            border: 0 !important;

            div {
              border: 0 !important;
            }
          `}
          onChange={(value) => setFilter(Number(value.value))}
          options={listOfYearsOptions}
        />
      )}
      {enlargeFirstArticle && (
        <FlexCard
          width={gridMap['two-thirds']}
          mediaWidth={{ md: 6 }}
          media={
            <AspectImage
              src={newsArticleCollection.items[0].image.url}
              aspectRatio="2/1"
            />
          }
          action={
            <ReadMoreLink
              to={`/${localeLower}/${newsArticleCollection.items[0].linkedFrom.urlSlugCollection.items[0].slug}`}
            >
              Read more
              <ChevronRight color={theme.colors.text.secondary.primary} />
            </ReadMoreLink>
          }
        >
          <p
            css={css`
              font-size: 15px;
              margin: 0 0 5px;
            `}
          >
            {`${newsArticleCollection.items[0].location}, ${formatDate(
              newsArticleCollection.items[0].publicationDate,
            )}`}
          </p>
          <Headline as={'h2'}>{newsArticleCollection.items[0].title}</Headline>
          <p
            css={css`
              margin: 0;
            `}
          >
            {newsArticleCollection.items[0].subtitle}
          </p>
        </FlexCard>
      )}
      <FlexCard
        noPadding={true}
        noSpacing={true}
        backgroundColor={'transparent'}
        width={enlargeFirstArticle ? { md: 4 } : gridMap[blockWidth]}
      >
        <List
          locale={localeLower}
          enlargeFirstArticle={enlargeFirstArticle}
          articleItemSize={articleItemSize}
          newsArticleCollection={articlesToShow}
          onLoadMore={() => onLoadMore()}
          blockWidth={blockWidth}
          link={link}
          showLoadMore={
            showLoadMore &&
            data.newsArticleCollection.items.length <
              data.newsArticleCollection.total
          }
          loadButtonTitle={loadButtonTitle}
        />
      </FlexCard>
    </>
  )
}

export const NewsArticleListFragment = graphql`
  fragment NewsArticleList on contentful_NewsArticleList {
    __typename
    sys {
      id
    }
    showFilter
    showLoadMore
    articlesToShowInitially
    loadButtonTitle
    enlargeFirstArticle
    articleItemSize
    blockWidth
    link {
      ...ItemLink
    }
  }
`

export default NewsArticleList
