import React from 'react'
import Cookies from 'js-cookie'
import { Container, Column, Radio, Label } from '@dfds-ui/react-components'
import { Text } from '@dfds-ui/typography'

import {
  FormContextProvider,
  FormField,
  FirstLastNameFields,
  GdprFormCheckbox,
  FormSubmitButton,
  AddressSelectField,
  useFormSubmit,
  PhoneNumberField,
} from './../form'
import { string } from 'yup'
import styled from '@emotion/styled'
import { graphql, navigate } from 'gatsby'
import { splitEvery, cond, always, equals } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useLocaleContext } from '../LocaleContext'
import { track } from '@dfds-frontend/tracking'
import { FlexCard } from '../Card'
import { EntryLink } from '../EntryLink'

const StyledRadio = styled(Radio)`
  margin-bottom: 10px;
`

const reasons = [
  {
    label: 'GENERAL-FREIGHT-SHIPPING',
    id: 'freight',
  },
  {
    label: 'CONTACT-US-DOOR-TO-DOOR-LOGISTICS-SOLUTIONS',
    id: 'doorToDoor',
  },
  {
    label: 'CONTACT-US-TRAVELLING-WITH-US',
    id: 'travelingWithUs',
  },
  {
    label: 'CONTACT-US-HAULAGE',
    id: 'haulage',
  },
  {
    label: 'CONTACT-US-DRIVING-FOR-US',
    id: 'drivingForUs',
  },
  {
    label: 'CONTACT-US-OTHER',
    id: 'other',
  },
]

export const FormContactUs = ({
  formTitle,
  interestFieldLabel,
  gdprCheckboxLabel,
  haulageApplicationLink,
  haulageApplicationLinkText,
  driverApplicationLink,
  driverApplicationLinkText,
  requiredFieldError,
  inputFormatError,
  emailTemplatesOtherCollection,
  emailTemplatesFreightShippingCollection,
  emailTemplatesLogisticsCollection,
  emailTemplatesTravellingWithUsCollection,
  thankYouPageSlug,
  errorMessageSendingWrong,
  sys,
}) => {
  const [reason, setReason] = React.useState(reasons[0].id)
  const { t } = useTranslation()
  const {
    locale,
    localeLower,
    language,
    country: countryCode,
  } = useLocaleContext()
  const {
    error,
    submitForm,
    isSubmitted,
    setReCaptchaRef,
    setIsReCaptchaVisible,
  } = useFormSubmit()
  const getTemplate = (collection) =>
    collection.items.reduce(
      (acc, { region, sys }) => ({
        ...acc,
        [region.regionName]: sys.id,
      }),
      {},
    )
  const handleSubmit = async (values) => {
    const getTemplateCollectionBy = cond([
      [equals('freight'), always(emailTemplatesFreightShippingCollection)],
      [equals('doorToDoor'), always(emailTemplatesLogisticsCollection)],
      [
        equals('travelingWithUs'),
        always(emailTemplatesTravellingWithUsCollection),
      ],
      [equals('other'), always(emailTemplatesOtherCollection)],
    ])
    const templates = getTemplate(getTemplateCollectionBy(reason))
    const country = values.location.value.split(', ')[1]
    await submitForm({
      languageId: locale,
      templateId: templates[country] || templates.Default,
      formData: {
        ...values,
        phone_number: values.phoneCountryCode + values.phoneNumber,
        location: values.location.value,
      },
      formType: 'formContactUs',
    })
    track({
      event: 'formSubmit',
      formName: 'contactusform',
      formID: sys.id,
      countryCode,
      languageCode: language,
      gaCookie: Cookies.get('_ga') || '',
      form_payload: JSON.stringify(values),
    })
  }
  const onReasonChange = (e) => setReason(e.target.value)
  const isDrivers = reason === 'drivingForUs'
  const isHaulage = reason === 'haulage'
  const shouldShowForm = !(isDrivers || isHaulage)
  const stringValidation = string()
  const emailValidation = string().email(inputFormatError)

  if (isSubmitted) {
    navigate(`/${localeLower}/${thankYouPageSlug.slug}`)
  }
  return (
    <FlexCard>
      <FormContextProvider
        requiredFieldError={requiredFieldError}
        onSubmit={handleSubmit}
      >
        <Container>
          <Column m={12}>
            <Text styledAs={'sectionHeadline'}>{formTitle}</Text>
            <Label>{interestFieldLabel}</Label>
          </Column>
          {splitEvery(3, reasons).map((column, index) => (
            <Column m={6} key={column[index].id}>
              {column.map(({ label, id }) => (
                <StyledRadio
                  key={id}
                  name={id}
                  value={id}
                  checked={reason === id}
                  onChange={onReasonChange}
                >
                  {t(label)}
                </StyledRadio>
              ))}
            </Column>
          ))}
          {shouldShowForm ? (
            <>
              <FirstLastNameFields required />
              <Column m={12}>
                <AddressSelectField
                  name="location"
                  label={t('FORM-LOCATION')}
                  validationMessage={requiredFieldError}
                  required
                />
                <FormField
                  name="email"
                  label={t('FORM-EMAIL-ADDRESS')}
                  type="text"
                  validation={emailValidation}
                  required
                />
                <PhoneNumberField
                  required
                  columnProps={{ m: 12 }}
                  labelProps={{ text: t('FORM-PHONE-NUMBER') }}
                  countryCodeProps={{
                    name: 'phoneCountryCode',
                    validation: stringValidation,
                  }}
                  numberProps={{
                    name: 'phoneNumber',
                    validation: stringValidation,
                  }}
                />
                <FormField name="topic" label={t('FORM-TOPIC')} type="text" />
                <FormField
                  name="message"
                  label={t('FORM-YOUR-MESSAGE')}
                  type="text"
                  validation={stringValidation}
                  required
                  multiline
                />
                <GdprFormCheckbox
                  label={gdprCheckboxLabel.labelText}
                  required
                />
                <FormSubmitButton
                  handleSubmit={handleSubmit}
                  reCaptchaProps={{
                    setReCaptchaRef,
                    setIsReCaptchaVisible,
                  }}
                />
                {error && (
                  <div style={{ marginTop: 10, color: 'red' }}>
                    {errorMessageSendingWrong}
                  </div>
                )}
              </Column>
            </>
          ) : (
            <Column>
              {isDrivers && (
                <EntryLink
                  {...driverApplicationLink}
                  linkText={driverApplicationLinkText}
                  textTransform={'none'}
                  fontWeight={400}
                />
              )}
              {isHaulage && (
                <EntryLink
                  {...haulageApplicationLink}
                  linkText={haulageApplicationLinkText}
                  textTransform={'none'}
                  fontWeight={400}
                />
              )}
            </Column>
          )}
        </Container>
      </FormContextProvider>
    </FlexCard>
  )
}

export const query = graphql`
  fragment FormContactUs on contentful_FormContactUs {
    __typename
    sys {
      id
    }
    formTitle
    interestFieldLabel
    gdprCheckboxLabel {
      labelText
    }
    haulageApplicationLinkText
    haulageApplicationLink {
      ...ItemLink
    }
    driverApplicationLinkText
    driverApplicationLink {
      ...ItemLink
    }
    requiredFieldError
    inputFormatError
    formSubmitSuccessMessage
    errorMessageSendingWrong
    thankYouPageSlug {
      slug
    }
    emailTemplatesOtherCollection(limit: 1) {
      items {
        ...EmailTemplate
      }
    }
    emailTemplatesFreightShippingCollection(limit: 10) {
      items {
        ...EmailTemplate
      }
    }
    emailTemplatesLogisticsCollection(limit: 32) {
      items {
        ...EmailTemplate
      }
    }
    emailTemplatesTravellingWithUsCollection(limit: 32) {
      items {
        ...EmailTemplate
      }
    }
  }

  fragment EmailTemplate on contentful_EmailTemplate {
    sys {
      id
    }
    region {
      regionName
    }
  }
`
